import 'rc-switch/assets/index.css';
import 'easymde/dist/easymde.min.css';
import { Form, Formik } from 'formik';
import getValue from 'get-value';
import moment from 'moment';
import Switch from 'rc-switch';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SimpleMDE from 'react-simplemde-editor';
import slugify from 'slugify';
import * as Yup from 'yup';
import Button, { LoadingButton } from '@atlaskit/button';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import Select from '@atlaskit/select';
import TextArea from '@atlaskit/textarea';
import Card from '../components/Card';
import FileField from '../components/FileField';
import Flex from '../components/Flex';
import GraphqlError from '../components/GraphqlError';
import Icon from '../components/Icon';
import Page from '../components/Page';
import TextField from '../components/TextField';
import { POST_QUERY, POSTS_QUERY } from '../graphql/graphqlQueries';
import {
  useCategoriesQuery,
  useCreatePostMutation,
  useFiltersQuery,
  usePostQuery,
  useUpdatePostMutation,
  useUsersQuery,
} from '../graphql/types';
import { Options } from 'easymde';

const CreateAndEditPostSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Zadejte minimálně 2 znaky').required('Toto pole je povinné.'),
  slug: Yup.string()
    .min(2, 'Zadejte minimálně 2 znaky')
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Zadejte pouze malá písmena bez diakritiky, pomlčku (-) a čísla.')
    .required('Toto pole je povinné.'),
  image: Yup.string().url().required('Toto pole je povinné.'),
  imageAlt: Yup.string().min(2).required('Toto pole je povinné.'),
  perex: Yup.string().min(2, 'Zadejte minimálně 2 znaky').required('Toto pole je povinné.'),
  content: Yup.string().min(2, 'Zadejte minimálně 2 znaky').required('Toto pole je povinné.'),
  user: Yup.string().required('Toto pole je povinné.'),
  category: Yup.string().required('Toto pole je povinné.'),
});

const CreatePostPage = () => {
  const navigate = useNavigate();
  const { postId } = useParams<'postId'>();

  const postIdParam = postId !== 'add' ? postId : undefined;
  const categoriesFetcher = useCategoriesQuery();
  const usersFetcher = useUsersQuery({ variables: { adminOnly: true } });
  const filtersFetcher = useFiltersQuery();
  const postQuery = usePostQuery({ variables: { id: postIdParam + '' }, skip: !postIdParam });
  const [executeCreatePostMutation, createPostMutation] = useCreatePostMutation();
  const [executeUpdatePostMutation, updatePostMutation] = useUpdatePostMutation();
  const [isPublished, setIsPublished] = useState(!(postQuery.data && postQuery.data.post.publishedAt === null));
  const simpleMdeOptions = React.useMemo(() => {
    return {
      spellChecker: false,
      status: false,
      toolbar: [
        'bold',
        'italic',
        'heading-2',
        'heading-3',
        'unordered-list',
        'ordered-list',
        '|',
        'link',
        'image',
        '|',
        'preview',
        'guide',
      ],
    } as Options;
  }, []);

  if (postIdParam && postQuery.called && !postQuery.data) {
    return <Page title="Upravit příspěvek" isLoading={postQuery.loading} isError={!!postQuery.error} />;
  }

  if (
    usersFetcher.loading ||
    usersFetcher.error ||
    filtersFetcher.loading ||
    filtersFetcher.error ||
    categoriesFetcher.loading ||
    categoriesFetcher.error ||
    (postIdParam && !postQuery.data)
  ) {
    return (
      <Page
        title={!postIdParam ? 'Přidat příspěvek' : 'Upravit příspěvek'}
        isLoading={categoriesFetcher.loading || filtersFetcher.loading || usersFetcher.loading}
        isError={!!categoriesFetcher.error || !!filtersFetcher.error || !!usersFetcher.error}
      />
    );
  }

  if (
    (createPostMutation.data && getValue(createPostMutation.data, 'createPost.id')) ||
    (updatePostMutation.data && getValue(updatePostMutation.data, 'updatePost.id'))
  ) {
    navigate(`/posts`);
  }

  return (
    <Page title={!postIdParam ? 'Přidat příspěvek' : 'Upravit příspěvek'}>
      <Formik
        initialValues={{
          name: postIdParam ? getValue(postQuery, 'data.post.name') : '',
          slug: postIdParam ? getValue(postQuery, 'data.post.slug') : '',
          image: postIdParam ? getValue(postQuery, 'data.post.image') : '',
          imageAlt: postIdParam ? getValue(postQuery, 'data.post.imageAlt') : '',
          perex: postIdParam ? getValue(postQuery, 'data.post.perex') : '',
          publishedAt: postIdParam ? getValue(postQuery, 'data.post.publishedAt') : '',
          content: postIdParam ? getValue(postQuery, 'data.post.content') : '',
          category: postIdParam ? getValue(postQuery, 'data.post.category.id') : '',
          user: postIdParam ? getValue(postQuery, 'data.post.user.id') : '',
        }}
        isInitialValid={!!postIdParam}
        validationSchema={CreateAndEditPostSchema}
        onSubmit={async (values) => {
          if (postIdParam) {
            executeUpdatePostMutation({
              variables: {
                ...values,
                publishedAt: values.publishedAt === '' ? null : values.publishedAt,
                id: postIdParam,
              },
              refetchQueries: [{ query: POSTS_QUERY }, { query: POST_QUERY, variables: { id: postIdParam } }],
            });

            if (isPublished && values.publishedAt !== '') {
              await fetch('https://hook.integromat.com/vouv6kfsn5fxi9yamacz687wypgfebaa', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  name: values.name,
                }),
              });
            }
          } else {
            executeCreatePostMutation({
              variables: {
                ...values,
                publishedAt: values.publishedAt === '' ? null : values.publishedAt,
              },
              refetchQueries: [{ query: POSTS_QUERY }],
            });
          }
        }}
      >
        {(props) => (
          <Form>
            <GraphqlError
              error={createPostMutation.error || updatePostMutation.error}
              defaultMessage={`Nepovedlo se příspěvek uložit. ${
                updatePostMutation.error
                  ? updatePostMutation.error.message
                  : createPostMutation.error
                  ? createPostMutation.error.message
                  : null
              }`}
            />

            <Flex>
              <TextField
                formikProps={props}
                label="Název"
                name="name"
                autoFocus={!postIdParam}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  props.setFieldTouched('name', true);
                  props.setFieldValue('name', e.target.value);
                  if (!postIdParam) {
                    props.setFieldTouched('slug', true);
                    props.setFieldValue('slug', slugify(e.target.value, { lower: true, remove: /[*+,~.()'"!:@]/g }));
                  }
                }}
              />
              <TextField
                formikProps={props}
                label="Slug"
                name="slug"
                elemAfterInput={
                  <Button
                    iconBefore={<Icon name="update-left-rotation" />}
                    onClick={() => {
                      props.setFieldTouched('slug', true);
                      props.setFieldValue(
                        'slug',
                        slugify(props.values.name, {
                          lower: true,
                          remove: /[*+,~.()'"!:@]/g,
                        }),
                      );
                    }}
                  >
                    Aktualizovat
                  </Button>
                }
              />

              <Card title="Autor">
                <Select
                  options={
                    usersFetcher.data && usersFetcher.data.users
                      ? [
                          ...usersFetcher.data.users.map((user) => ({
                            label: user.firstName + ' ' + user.lastName,
                            value: user.id,
                          })),
                        ]
                      : {}
                  }
                  name="users"
                  onChange={(item: { value: string; label: string }) => {
                    props.setFieldValue('user', item.value);
                  }}
                  value={
                    usersFetcher.data &&
                    usersFetcher.data.users
                      .map((user) => ({ label: user.firstName + ' ' + user.lastName, value: user.id }))
                      .find((user) => user.value === props.values.user)
                  }
                  isSearchable={true}
                  placeholder="Vyberte autora"
                />
              </Card>

              <Card title="Kategorie">
                <Select
                  options={
                    categoriesFetcher.data && categoriesFetcher.data.categories
                      ? [
                          ...categoriesFetcher.data.categories.map((category) => ({
                            label: category.name,
                            value: category.id,
                          })),
                        ]
                      : {}
                  }
                  name="categories"
                  onChange={(item: { value: string; label: string }) => {
                    props.setFieldValue('category', item.value);
                  }}
                  value={
                    categoriesFetcher.data &&
                    categoriesFetcher.data.categories
                      .map((category) => ({ label: category.name, value: category.id }))
                      .find((category) => category.value === props.values.category)
                  }
                  isSearchable={true}
                  placeholder="Vyberte kategorii"
                />
              </Card>
              <Card title="Publikováno">
                <Switch
                  checked={isPublished}
                  onClick={() => {
                    setIsPublished(!isPublished);

                    if (isPublished) {
                      props.setFieldValue('publishedAt', '');
                    }
                  }}
                  checkedChildren={null}
                  unCheckedChildren={null}
                />
                {isPublished && (
                  <div style={{ marginTop: 10 }}>
                    <DateTimePicker
                      value={props.values.publishedAt === null ? '' : props.values.publishedAt}
                      onChange={(value) => props.setFieldValue('publishedAt', moment(value).utc().toISOString())}
                      locale="cs_CZ"
                      times={[
                        '00:00',
                        '08:00',
                        '08:30',
                        '09:00',
                        '09:30',
                        '10:00',
                        '10:30',
                        '11:00',
                        '11:30',
                        '12:00',
                        '12:30',
                        '13:00',
                        '13:30',
                        '14:00',
                        '14:30',
                        '15:00',
                        '15:30',
                        '16:00',
                        '16:30',
                        '17:00',
                        '17:30',
                        '18:00',
                        '18:30',
                        '19:00',
                        '19:30',
                      ]}
                    />
                  </div>
                )}
              </Card>
              <TextField formikProps={props} label="Alt hlavního obrázku" name="imageAlt" />
            </Flex>
            <Card title="Obrázek">
              <FileField
                updateParentState={(url) => {
                  props.setFieldValue('image', url);
                }}
                defaultState={props.values.image}
                photoName={props.values.imageAlt}
              />
            </Card>
            <Card title="Perex">
              <TextArea
                value={props.values.perex}
                onChange={(e) => {
                  props.setFieldValue('perex', e.target.value);
                  props.setFieldTouched('perex', true);
                }}
                minimumRows={5}
              />
            </Card>
            <Card title="Obsah">
              <SimpleMDE
                value={props.values.content}
                onChange={(value) => {
                  props.setFieldValue('content', value);
                  props.setFieldTouched('content', true);
                }}
                options={simpleMdeOptions}
              />
            </Card>
            <div style={{ marginTop: 20 }}>
              <LoadingButton
                type="submit"
                isLoading={createPostMutation.loading || updatePostMutation.loading}
                isDisabled={!props.isValid || createPostMutation.loading || updatePostMutation.loading}
                appearance="primary"
              >
                Uložit
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreatePostPage;
